import { Spin, notification } from "antd";
import { navigate } from "gatsby";
import { useMutation } from "@apollo/react-hooks";
import { UploadFile } from "antd/lib/upload/interface";
import idx from "idx";
import CREATE_BLOG_POST_MUTATION from "./createBlogPost.graphql";
import {
  CreateBlogPost,
  CreateBlogPostVariables,
} from "./types/CreateBlogPost";
import React, { FC, useCallback } from "react";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import BlogPostForm from "components/blogPostForm";
import routes from "routes";
import { CreateBlogPostDto } from "types/graphql-global-types";
import { useErrorHandler } from "utils";

const BlogPostCreate: FC = () => {
  const [createBlogPost, { loading, error: createBlogPostError }] = useMutation<
    CreateBlogPost,
    CreateBlogPostVariables
  >(CREATE_BLOG_POST_MUTATION, {
    refetchQueries: ["GetBlogPostList"],
  });

  useErrorHandler(createBlogPostError);

  const gotoBlogposts = useCallback(() => navigate(routes.blogPosts), []);

  const handleSubmit = useCallback(
    async (values: CreateBlogPostDto) => {
      const uploadFile: UploadFile = values.image;
      const image = idx(uploadFile, x => x.originFileObj) || undefined;
      const input = {
        ...values,
        image,
      };

      const { errors, data } = await createBlogPost({
        variables: {
          input,
        },
      });

      if (!errors) {
        notification.success({
          message: "Успех!",
          description: (
            <>
              Новость{" "}
              <strong>{idx(data, x => x.srv.blogPost.create.title)}</strong>{" "}
              была успешно создана
            </>
          ),
        });

        gotoBlogposts();
      }
    },
    [createBlogPost, gotoBlogposts]
  );

  return (
    <Authorize>
      <MainLayout title="Создание новости" onBack={gotoBlogposts}>
        <Spin spinning={loading}>
          <BlogPostForm onSubmit={handleSubmit} onCancel={gotoBlogposts} />
        </Spin>
      </MainLayout>
    </Authorize>
  );
};

export default BlogPostCreate;
